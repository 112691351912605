@import '../../../styles/customMediaQueries.css';

.root {
  position: relative;
  display: inline-block;
}

.fieldGroup {
  margin: 0;
  padding: 0;
  border: none;
}

.fieldGroupPlain {
  margin: 0;
  padding: 0;
  border: none;
}

.list {
  margin: 0;
}

.item {
  padding: 4px 0 2px 0;

  /* Fix broken multi-column layout in Chrome */
  page-break-inside: avoid;

  @media (--viewportMedium) {
    padding: 4px 0;
  }
  & > span {
    & > label {
      & > span {
        font-size: 14px;
        color: var(--colorGrey900);
        font-weight: var(--fontWeightRegular);
        & > svg {
          width: 15px;
          height: 15px;
        }
      }
    }
  }
}
