@import '../../../styles/customMediaQueries.css';

.root {
  position: relative;
  padding: 10px 16px;
  border-bottom: 1px solid var(--colorGrey100);
}

.filterHeader {
  line-height: 24px;
}

.labelButton {
  /* Override button styles */
  width: 100%;
  outline: none;
  line-height: 24px;
  text-align: left;
  border: none;
  padding: 0;
  cursor: pointer;
}

.labelButtonContent {
  display: inline-block;
  width: 100%;

  &:hover {
    color: var(--colorGrey900);

    & * {
      color: var(--colorGrey900);
      stroke: var(--colorGrey900);
    }
  }
}

.labelWrapper {
  display: inline-block;
  max-width: calc(100% - 16px);
}

.label,
.labelSelected {
  composes: p from global;
  font-weight: var(--fontWeightBold);
  color: var(--colorGrey900);
  font-size: 14px;

  /* Baseline adjustment for label text */
  margin-top: 0;
  margin-bottom: 12px;

  @media (--viewportMedium) {
    margin-bottom: 8px;
  }
}

.label {
  color: var(--colorGrey900);
}

.labelSelected {
  color: var(--colorGrey900);
  margin-left: 5px;
}

.openSign {
  float: right;
}

.clearButton {
  composes: p from global;
  margin: 0;
  padding: 0;
  display: block;
  font-weight: var(--fontWeightRegular);
  font-size: 14px;
  color: var(--marketplaceColor);

  /* Override button styles */
  outline: none;
  text-align: left;
  border: none;
  cursor: pointer;

  &:focus,
  &:hover {
    text-decoration: underline;
  }
}

.plain {
  width: 100%;
  /* display: none; */
}

.isOpen {
  /* display: block; */
}
