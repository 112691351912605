@import '../../../styles/customMediaQueries.css';
.root {
  outline: none;
}

.contentWrapper {
  margin-bottom: 0;
  padding: 10px 0;
  @media (--viewportMedium) {
    & :global(.DayPickerNavigation_button__horizontal:first-of-type) {
      left: 0;
    }
    & :global(.DayPickerNavigation_button__horizontal:last-of-type) {
      right: 0;
    }
    & :global(.DayPicker) {
      width: 100% !important;
      & > div {
        & > div {
          width: 100% !important;
        }
      }
    }
    & :global(.DayPicker_transitionContainer) {
      width: 100% !important;
    }
    & :global(.CalendarMonth_caption) {
      font-size: 12px;
      padding-top: 25px;
      padding-bottom: 26px;
    }

    & :global(.CalendarDay),
    & :global(.DayPicker_weekHeader_li) {
      font-size: 12px;
    }
    & :global(.CalendarMonthGrid_month__horizontal),
    & :global(.DayPicker_weekHeader) {
      max-width: 160px;
    }
    & :global(.CalendarMonth),
    & :global(.DayPicker_weekHeader) {
      padding: 0 !important;
    }
    & :global(.DayPicker_weekHeader_ul) {
      display: flex;
    }
  }
  @media (--viewportLarge) {
    & :global(.DayPicker) {
      width: 100% !important;
      & > div {
        & > div {
          width: 100% !important;
        }
      }
    }
    & :global(.DayPicker_transitionContainer) {
      width: 100% !important;
    }
    & :global(.CalendarMonth_caption) {
      font-size: 13px;
    }

    & :global(.CalendarDay),
    & :global(.DayPicker_weekHeader_li) {
      font-size: 10px;
    }
    & :global(.CalendarMonthGrid_month__horizontal),
    & :global(.DayPicker_weekHeader) {
      max-width: 200px;
    }
    & :global(.CalendarMonth),
    & :global(.DayPicker_weekHeader) {
      padding: 0 !important;
    }
    & :global(.DayPicker_weekHeader_ul) {
      display: flex;
    }
  }
}

.buttonsWrapper {
  display: flex;
  padding: 0 30px 16px 30px;
}

.clearButton,
.cancelButton,
.submitButton {
  composes: textSmall from global;
  font-weight: var(--fontWeightMedium);

  /* Layout */
  padding: 0;

  /* Override button styles */
  outline: none;
  border: none;
  cursor: pointer;

  &:focus,
  &:hover {
    transition: width var(--transitionStyleButton);
  }
}

.clearButton {
  color: var(--colorGrey300);

  /* Layout */
  margin: 0 auto 0 0;

  &:focus,
  &:hover {
    color: var(--colorGrey700);
  }
}

.cancelButton {
  color: var(--colorGrey300);

  /* Layout */
  margin: 0;

  /* clearButton will add all available space between cancelButton,
   * but some hard coded margin is still needed
   */
  margin-left: 48px;

  &:focus,
  &:hover {
    color: var(--colorGrey700);
  }
}

.submitButton {
  color: var(--marketplaceColor);

  /* Layout */
  margin: 0 0 0 19px;

  &:focus,
  &:hover {
    color: var(--marketplaceColorDark);
  }
}
